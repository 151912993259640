<template>
  <div>
    <EuiButton :selected="isMenuOpen" :id="`menu-${this.field}`" iconOnly size="small" color="primary"><EuiIcon name="more_vert"/></EuiButton>

    <EuiMenu ref="menu" @open="onMenuOpening" @close="onMenuClosing">
      <EuiMenuPanel :anchor="`menu-${this.field}`" trigger="clickToOpen" placement="bottom-start">
        <EuiCard>
          <EuiMenuItem v-if="moveColumns" @click:menu-item="$emit('moveToLeft')">{{ $t('cardFilter.moveToLeft')}}</EuiMenuItem>
          <EuiMenuItem v-if="moveColumns" @click:menu-item="$emit('moveToRight')">{{ $t('cardFilter.moveToRight')}}</EuiMenuItem>
          <EuiMenuItem v-if="filterType.length" anchor :id="`cardFilter-${this.field}`" @click.native="resetFilter">{{ $t('cardFilter.button.filter')}}</EuiMenuItem>
        </EuiCard>
      </EuiMenuPanel>

      <EuiMenuPanel :anchor="`cardFilter-${this.field}`" trigger="clickToOpen">
        <EuiCardFilter :searchPlaceholder="isSearchable"
                       :buttonText="$t('cardFilter.button.filter')"
                       v-model="searchBarValue"
                       @enter:searchBar="addSearchTag"
                       @click:filter="addFilter"
                       :disabled="!canUpdate">
          <template v-if="field === 'organizations' || field === 'senderName'" #header>
            <EuiAutocomplete v-model="selectedOrg"
                             :options="organizations.map(x => x.name)"
                             style="z-index: 2"
                             adaptSize
                             :placeholder="$t('navbar.drawer.connect-to-org.search')"/>
          </template>
          <EuiList v-if="searchTags.length > 0">
            <EuiListItem v-for='(searchTag, index) in searchTags'
                         :key='index'
                         style="font-size: 14px; font-weight: 500">
              {{ searchTag }}
              <EuiButton class='eui-u-ml-auto' iconOnly color="primary" @click="deleteSearchTag(searchTag)"><EuiIcon name="delete"/></EuiButton>
            </EuiListItem>
          </EuiList>
          <EuiDivider secondary v-if="searchTags.length > 0"/>
          <EuiButtonGroup style="z-index: 1" v-if="filterType.includes('sortable')" vertical mode="radio" #default="{ state, setState }" @change="setSort">
            <EuiButton :selected="state.includes('ASC')" @click="setState('ASC')">{{ getFilterType('ASC') }}</EuiButton>
            <EuiButton :selected="state.includes('DESC')" @click="setState('DESC')">{{ getFilterType('DESC') }}</EuiButton>
          </EuiButtonGroup>
          <div v-if="filterType.includes('date')" class="eui-u-pl-2 eui-u-py-3">
            <div v-for="(datepicker, index) in datepickerOptions" :key="index" class="eui-u-p -small eui-u-ml-5" style="font-weight:500" >{{ datepicker }}
              <EuiInput type="date" v-model="dateFilters[index]" :disabled="checkDateSelected(index)"/>
            </div>
          </div>
          <div class="eui-u-flex eui-u-flex-col eui-u-p-3" v-if="filterType.includes('radio')">
            <EuiRadio v-for="(radio,index) in radioOptions" :key="index" name="radio" :value="radio.status" v-model="selected" >{{ radio.status }}</EuiRadio>
          </div>
        </EuiCardFilter>
      </EuiMenuPanel>
    </EuiMenu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SubMenu',
  data() {
    return {
      selectedOrg: '',
      searchBarValue: undefined,
      searchTags: [],
      sort: [],
      isMenuOpen: false,
      selected: null,
      datepickerOptions: ['Date', 'Début', 'Fin'],
      dateFilters: [],
    };
  },
  computed: {
    canUpdate() {
      return this.searchTags.length > 0 || this.sort.length > 0 ||
        this.dateFilters.length > 0 || this.selected !== null || this.selectedOrg !== '';
    },
    isSearchable() {
      if (this.filterType.includes('date') || this.filterType.includes('radio')) {
        return '';
      }
      return this.$i18n.t('cardFilter.searchBar.placeholder');
    },
    ...mapGetters({
      organizations: 'organizations/organizations',
    }),
  },
  props: {
    filterType: {
      type: [Array, Boolean],
      default: () => [],
    },
    field: {
      type: [String, Object],
    },
    name: {
      type: String,
    },
    radioOptions: Array,
    moveColumns: Boolean,
  },
  methods: {
    getFilterType(sort) {
      if (sort === 'ASC') {
        if (this.filterType.includes('date') || this.filterType.includes('number')) {
          return this.$i18n.t('cardFilter.sort.number.increasing');
        }
        return this.$i18n.t('cardFilter.sort.alphabetical.increasing');
      }
      if (sort === 'DESC') {
        if (this.filterType.includes('date') || this.filterType.includes('number')) {
          return this.$i18n.t('cardFilter.sort.number.decreasing');
        }
        return this.$i18n.t('cardFilter.sort.alphabetical.decreasing');
      }
      return null;
    },

    checkDateSelected(index) {
      if (index === 0) {
        if (this.dateFilters.length > 1) {
          return true;
        }
      } else if (this.dateFilters[0] !== undefined) {
        return true;
      }
      return false;
    },

    resetFilter() {
      this.searchTags = [];
      this.sort = [];
    },

    addSearchTag() {
      this.searchTags = [this.searchBarValue];
      this.searchBarValue = undefined;
    },

    deleteSearchTag(searchTag) {
      const index = this.searchTags.indexOf(searchTag);
      this.searchTags.splice(index, 1);
    },

    setSort(sort) {
      this.sort = sort;
    },

    addFilter() {
      if (this.selectedOrg !== '') {
        const org = this.organizations.find((x) => x.name === this.selectedOrg);
        this.searchTags.push(org.id);
      }
      if (this.dateFilters.length) {
        this.searchTags.push({ creationDateStart: this.dateFilters[0] !== undefined ? this.dateFilters[0] : this.dateFilters[1], creationDateEnd: this.dateFilters[0] !== undefined ? this.dateFilters[0] : this.dateFilters[2] });
      }
      if (this.selected !== null) {
        this.searchTags.push(this.selected);
      }
      this.$emit('addFilter', {
        field: this.field,
        name: this.name,
        filterType: this.filterType,
        searchTags: this.searchTags,
        sort: this.sort,
      });
      this.searchTags = [];
      this.sort = [];
      this.dateFilters = [];
      this.$refs.menu.close();
    },

    onMenuOpening() {
      this.isMenuOpen = true;
    },

    onMenuClosing() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style>
  .eui-a-menuItem {
    font-size: 12px;
  }

  .eui-m-cardFilter {
    z-index: 10;
  }

  .eui-m-menuPanel__content {
    z-index: 10;
  }

  .eui-m-autocomplete__placeholder {
    font-size: 14px;
    bottom: -5px;
  }

  .eui-m-cardFilter__content {
    width: auto;
    padding: 0;
  }

  .eui-m-cardFilter__content > .eui-a-divider {
    width: 100%;
    margin: 0;
  }

  .eui-m-cardFilter__content > .eui-m-buttonGroup > .eui-a-button {
    padding: 6px 21px;
  }

  .eui-m-cardFilter__content .eui-a-listItem {
    padding-top: 0;
    padding-bottom: 0;
  }

  .eui-a-listItem {
    padding-left: 21px;
    padding-right: 21px;
  }
</style>
