export default {
  /**
   * The name of the component.
   */
  name: 'table-global-actions',

  methods: {
    move(field, direction, vueOrder, headers, setVueOrderAction) {
      let vueOrderTmp = JSON.parse(JSON.stringify(vueOrder));
      if (vueOrderTmp === null || !vueOrderTmp.length) {
        vueOrderTmp = [];
        headers.forEach((header) => {
          vueOrderTmp.push(header.field);
        });
      }
      const index = vueOrderTmp.indexOf(field);
      switch (direction) {
        case 'left':
          if (index > 0) {
            vueOrderTmp.splice(index - 1, 0, field);
            vueOrderTmp.splice(index + 1, 1);
          }
          break;
        case 'right':
          if (index < vueOrderTmp.length - 1) {
            vueOrderTmp.splice(index + 2, 0, field);
            vueOrderTmp.splice(index, 1);
          }
          break;
        default:
          break;
      }
      return setVueOrderAction(vueOrderTmp);
    },
  },

};
